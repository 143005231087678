import { h, Component } from 'preact';

class PaginationCount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentAmount: 0,
      total: 0,
    };

    this.watchStore = this.watchStore.bind(this)
  }

  componentDidMount() {
    this.watchStore()
  }
  
  watchStore() {
    window.store.UsedVehiclesFilter.watch(state => {
      const { total, per_page, page } = state
      
      const isLastPage = total / per_page <= page
      const currentAmount = isLastPage ? total : per_page * page
      
      this.setState({
        currentAmount,
        total,
      });
    });
  }
  
  render() {
    const { currentAmount, total } = this.state
    
    return (
      <div className="pagination-count">
        { total === null && <p className="pagination-count__content">Buscando...</p>}
        { (total !== null && !!total) && <p className="pagination-count__content">Exibindo <b>{ currentAmount || '0' }</b> de <b>{total || '0'}</b> veículos</p>}
        { (total !== null && !total) && <p className="pagination-count__content">Nenhum veículo encontrado.</p>}
      </div>
    );
  }
}

PaginationCount.defaultProps = {
};

PaginationCount.propTypes = {
};

export default PaginationCount;
