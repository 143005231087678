// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import { setComponentsExhibition } from '../../../../../stores/Filters/UsedVehicles/FilterV2';

class ButtonAdvancedFilter extends Component {
  handleShowFilter() {
    setComponentsExhibition({ advancedFilter: true });
  }

  render() {
    const { buttonLabel } = this.props;

    return (
      <button
        className="btn button button--large simple-filter-btn-filter simple-filter__item button--line-white"
        type="button"
        onClick={this.handleShowFilter}
      >
        <span className="d-none" data-testid="close">
          Fechar
        </span>
        <span className="" data-testid="open">
          <i className="icon icon-filter" />
          {buttonLabel}
        </span>
      </button>
    );
  }
}

ButtonAdvancedFilter.defaultProps = {
  buttonLabel: 'Filtrar',
};

ButtonAdvancedFilter.propTypes = {
  buttonLabel: PropTypes.string,
};

export default ButtonAdvancedFilter;
