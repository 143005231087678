export const unmapFiltersV2 = {
  search: 'keyword',
  kind: 'kind',
  'category[]': 'category',
  'brand[]': 'brand',
  model: 'model',
  'exchange[]': 'exchange',
  'color[]': 'color',
  'price[]': 'minPrice',
  'km[]': 'minKm',
  'model_year[]': 'minYear',
  'unit_id[]': 'unit',
  unit_state: 'state',
  unit_city: 'city',
  sort: 'sort',
};

export const unmapSimpleFiltersV2 = {
  search: 'keyword',
  kind: 'kind',
  'category[]': 'category',
  'brand[]': 'brand',
  model: 'model',
  'exchange[]': 'exchange',
  'color[]': 'color',
  'unit_id[]': 'unit',
  unit_state: 'state',
  unit_city: 'city',
  sort: 'sort',
  min_price: 'min_price',
  max_price: 'max_price',
  min_model_year: 'min_model_year',
  max_model_year: 'max_model_year',
  min_km: 'min_km',
  max_km: 'max_km',
};

export const unmapRangeFiltersV2 = {
  'km[]': {
    min: 'minKm',
    max: 'maxKm',
  },
  'model_year[]': {
    min: 'minYear',
    max: 'maxYear',
  },
  'price[]': {
    min: 'minPrice',
    max: 'maxPrice',
  },
};
