// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import {
  resetFilter,
  setComponentsExhibition,
  setList,
} from '../../../../stores/Filters/UsedVehicles/FilterV2';
import { clearURLFilters } from '../../../../utils/filters/usedVehicles/clearURLSearchParams';

class AdvancedFilterHeader extends Component {
  constructor(props) {
    super(props);

    this.handleClearFilters = this.handleClearFilters.bind(this);
  }

  handleCloseAdvancedFilter() {
    setComponentsExhibition({
      advancedFilter: false,
      results: false,
    });
  }

  handleClearFilters() {
    setComponentsExhibition({
      results: false,
    });
    resetFilter();
    setList();
    clearURLFilters();
    this.props.clearFilters();
  }

  render() {
    return (
      <div className="advanced-filter__header">
        <button
          className="advanced-filter__header-button"
          type="button"
          onClick={this.handleCloseAdvancedFilter}
        >
          ← Voltar para a Busca Simplificada
        </button>
        <button
          className="advanced-filter__header-button"
          type="button"
          onClick={this.handleClearFilters}
        >
          Limpar filtros
        </button>
      </div>
    );
  }
}

AdvancedFilterHeader.defaultProps = {
  clearFilters: () => {},
};

AdvancedFilterHeader.propTypes = {
  clearFilters: PropTypes.func,
};

export default AdvancedFilterHeader;
