export const makeFetchList = serviceApi => () => {
  const {
    methodApi,
    filtersList,
    page,
    per_page,
    sort
  } = window.store.FilterV2.getState();

  const defaultParams = [
    { active: true },
    { sort: sort || '-created_at' },
    { page },
    { per_page },
  ];
  const paramsList = [...defaultParams, ...filtersList];

  return serviceApi[methodApi](paramsList);
};
