// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import {
  resetFilter,
  setComponentsExhibition,
  setList,
} from '../../../../stores/Filters/UsedVehicles/FilterV2';
import AdvancedFilterButtonSubmit from './AdvancedFilterButtonSubmit';
import { clearURLFilters } from '../../../../utils/filters/usedVehicles/clearURLSearchParams';

class AdvancedFilterFooter extends Component {
  constructor(props) {
    super(props);

    this.handleClearFilters = this.handleClearFilters.bind(this);
  }

  handleClearFilters() {
    setComponentsExhibition({
      results: false,
    });
    resetFilter();
    setList();
    clearURLFilters();
    this.props.clearFilters();
  }

  render() {
    return (
      <div className="advanced-filter__footer">
        <button
          className="advanced-filter__footer-button"
          type="button"
          onClick={this.handleClearFilters}
        >
          Limpar filtros
        </button>
        <AdvancedFilterButtonSubmit
          handleSubmit={this.props.handleSubmit}
          buttonLabel="Buscar"
        />
      </div>
    );
  }
}

AdvancedFilterFooter.defaultProps = {
  clearFilters: () => {},
  handleSubmit: () => {},
};

AdvancedFilterFooter.propTypes = {
  clearFilters: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default AdvancedFilterFooter;
