// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import { setComponentsExhibition } from '../../../../stores/Filters/UsedVehicles/FilterV2';

class AdvancedFilterModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.watchStore = this.watchStore.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    this.watchStore();
  }

  handleCloseModal() {
    setComponentsExhibition({
      advancedFilter: false,
    });
  }

  watchStore() {
    window.store.FilterV2.watch(state => {
      this.setState(oldState => ({
        ...oldState,
        isOpen: state.componentsExhibition.advancedFilter,
      }));
    });
  }

  render() {
    const { children, title } = this.props;

    return (
      <div className="advanced-filter-modal" data-open={this.state.isOpen}>
        <div className="advanced-filter-modal__content">
          <div className="advanced-filter-modal__header">
            <p className="advanced-filter-modal__header-title">{title}</p>
            <button
              className="advanced-filter-modal__button-close"
              onClick={this.handleCloseModal}
              label="Fechar"
              type="button"
            >
              <i className="icon icon-close" />
            </button>
          </div>
          {children}
        </div>
      </div>
    );
  }
}

AdvancedFilterModal.defaultProps = {
  children: null,
  title: 'Filtrar pesquisa',
};

AdvancedFilterModal.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
};

export default AdvancedFilterModal;
