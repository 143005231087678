export const mapFiltersList = (filtersList, mapFilters) => {
  const filtersKeys = Object.keys(filtersList);
  const mappedFiltersList = filtersKeys.reduce((mapped, filterKey) => {
    if (!filtersList[filterKey]) return mapped;
    return [
      ...mapped,
      {
        [mapFilters[filterKey]]: filtersList[filterKey],
      },
    ];
  }, []);

  return mappedFiltersList;
};
