import { createStore, createEffect, createEvent } from 'effector';
import { makeFetchList } from '../../../utils/filters/usedVehicles/fetchList';
import { autoServiceApiV2 } from '../../../services/autoServiceApiV2';

const fetchListV2 = makeFetchList(autoServiceApiV2);

export const setList = createEffect('set list').use(fetchListV2);
export const incrementList = createEffect('increment list').use(fetchListV2);

export const setFiltersList = createEvent('set filter list');
export const resetList = createEvent('reset list');
export const incrementFilter = createEvent('increment filter');
export const resetFilter = createEvent('reset filter');
export const nextPage = createEvent('next page');
export const setPage = createEvent('set page');
export const setPerPage = createEvent('set per_page');
export const setSort = createEvent('set sort');
export const setKeyword = createEvent('set page');
export const setMethodApi = createEvent('set method api');

export const setComponentsExhibition = createEvent('set components exhibition');

const FilterV2 = (() => {
  let instance;

  return (
    defaultValue = {
      methodApi: 'getUsedModels',
      filtersList: [],
      sort: 'ordination,-created_at',
      keyword: '',
      list: [],
      total: null,
      total_pages: null,
      loading: false,
      loadingMore: false,
      page: 1,
      per_page: 12,
      hasError: false,
      componentsExhibition: {
        advancedFilter: false,
        results: false,
        formNoResults: false,
      },
    },
  ) => {
    if (!instance) {
      instance = createStore(defaultValue);

      instance.on(setList.pending, (state, loading) => ({
        ...state,
        loading,
      }));

      instance.on(setList.fail, state => ({
        ...state,
        loading: false,
        hasError: true,
      }));

      instance.on(setList.done, (state, res) => ({
        ...state,
        list: res.result.entries,
        total: res.result.total,
        total_pages: res.result.total_pages,
        loading: false,
        componentsExhibition: {
          ...state.componentsExhibition,
          formNoResults: res.result.entries.length === 0,
        },
      }));

      instance.on(incrementList.pending, (state, loading) => ({
        ...state,
        loading,
        loadingMore: loading,
      }));

      instance.on(incrementList.fail, state => ({
        ...state,
        loading: false,
        loadingMore: false,
        page: state.page - 1,
      }));

      instance.on(incrementList.done, (state, res) => ({
        ...state,
        list: [...state.list, ...res.result.entries],
        total: res.result.total,
        componentsExhibition: {
          ...state.componentsExhibition,
          formNoResults: res.result.entries.length === 0,
        },
        total_pages: res.result.total_pages,
        loading: false,
        loadingMore: false,
      }));

      instance.on(resetList, (state, list = []) => ({
        ...state,
        page: 1,
        list,
      }));

      instance.on(nextPage, state => ({
        ...state,
        page: state.page + 1,
      }));

      instance.on(setPage, (state, page) => ({
        ...state,
        page,
      }));

      instance.on(setPerPage, (state, per_page) => ({
        ...state,
        per_page,
      }));

      instance.on(setFiltersList, (state, filtersList) => ({
        ...state,
        filtersList,
      }));

      instance.on(incrementFilter, (state, filter) => ({
        ...state,
        filtersList: [...state.filtersList, filter],
      }));

      instance.on(resetFilter, state => ({
        ...state,
        filtersList: [],
      }));

      instance.on(setSort, (state, sort) => ({
        ...state,
        sort,
        filtersList: [...state.filtersList, { sort }],
        page: 1,
      }));

      instance.on(setKeyword, (state, keyword) => ({
        ...state,
        keyword,
      }));

      instance.on(setMethodApi, (state, methodApi) => ({
        ...state,
        methodApi,
      }));

      instance.on(
        setComponentsExhibition,
        (state, { advancedFilter, results, formNoResults }) => {
          const currentExhibition = state.componentsExhibition;
          const advancedFilterState =
            typeof advancedFilter !== 'undefined'
              ? advancedFilter
              : currentExhibition.advancedFilter;
          const resultsState =
            typeof results !== 'undefined'
              ? results
              : currentExhibition.results;
          const formNoResultsState =
            typeof formNoResults !== 'undefined'
              ? formNoResults
              : currentExhibition.formNoResults;

          return {
            ...state,
            componentsExhibition: {
              advancedFilter: advancedFilterState,
              results: resultsState,
              formNoResults: formNoResultsState,
            },
          };
        },
      );
    }

    return instance;
  };
})();

export default FilterV2;
