import './components';
import './stores';
import '../shared';
import {
  makeCarousel,
  makeUnmountedCarousel,
} from '../../auto/utils/carousel/splide';
import { autoServiceApiV2 } from '../../auto/services/autoServiceApiV2';
import autoServiceApi from '../../auto/utils/autoServiceApi';

window.makeCarousel = makeCarousel;
window.makeUnmountedCarousel = makeUnmountedCarousel;

window.services = {
  autoServiceApiV2,
};
window.autoServiceApi = autoServiceApi;
