import { Component } from 'preact';
import PropTypes from 'prop-types';
import ClassicUsedVehiclesCard from '../../../Cards/UsedVehicles/ClassicUsedVehiclesCard';
import CardPlaceholder from '../../../Cards/Placeholder';

class ListUsedVehiclesClassic extends Component {
  componentDidUpdate() {
    window.matchHeight.match('.used-vehicles-listing__content');
  }

  render() {
    const { 
      moduleLink,
      vehiclesList,
      loading,
      loadingMore,
      CustomCard
    } = this.props;

    return (
      <div
        className="used-vehicles-listing__content"
        data-vehicles-list-empty={!loading && vehiclesList.length === 0}
      >
        {loading &&
          !loadingMore &&
          Array.from({ length: 12 }).map(item => (
            <CardPlaceholder key={item} long />
          ))}
        {vehiclesList.length > 0 &&
          vehiclesList.map(vehicle => (
            <CustomCard
              key={vehicle.slug}
              slug={vehicle.slug}
              moduleLink={moduleLink}
              badge={vehicle.badge}
              brand={vehicle.brand}
              id={vehicle.id}
              itemImage={vehicle.item_image.middle_image}
              itemUnit={vehicle.item_unit}
              kind={vehicle.kind}
              name={vehicle.name}
              model={vehicle.model}
              version={vehicle.subtitle}
              modelYear={vehicle.model_year}
              fabricationYear={vehicle.fabrication_year}
              seals={vehicle.seals}
              subtitle={vehicle.subtitle}
              km={vehicle.km}
              exchange={vehicle.exchange}
              fuelText={vehicle.fuel_text}
              price={vehicle.price}
              oldPrice={vehicle.old_price}
            />
          ))}
        {!loading && vehiclesList.length === 0 && (
          <p className="used-vehicles-listing__no-vehicles-message">
            Nenhum veículo seminovo encontrado.
          </p>
        )}
      </div>
    );
  }
}

ListUsedVehiclesClassic.defaultProps = {
  vehiclesList: [],
  loading: true,
  loadingMore: false,
  moduleLink: null,
  CustomCard: ClassicUsedVehiclesCard,
};

ListUsedVehiclesClassic.propTypes = {
  vehiclesList: PropTypes.arrayOf(),
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  moduleLink: PropTypes.string,
  CustomCard: PropTypes.element,
};

export default ListUsedVehiclesClassic;
