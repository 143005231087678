// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class ControlledSelect extends Component {
  render() {
    const {
      customClasses,
      name,
      options,
      placeholder,
      placeholderValue,
      label,
      value,
      handleChange,
    } = this.props;

    return (
      <div className={classNames('form__select-field', customClasses)}>
        {label && <label className="form__select-field-label">{label}</label>}
        <select
          className="form__select-field-input"
          name={name}
          onChange={handleChange}
          value={value}
        >
          {placeholder && (
            <option value={placeholderValue} selected disabled>
              {placeholder}
            </option>
          )}
          {options &&
            options.map(option => (
              <option
                className="form__select-field-option"
                value={option.value}
                selected={option.selected}
              >
                {option.label}
              </option>
            ))}
        </select>
      </div>
    );
  }
}

ControlledSelect.defaultProps = {
  placeholderValue: 'empty',
  value: null,
};

ControlledSelect.propTypes = {
  customClasses: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderValue: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      selected: PropTypes.string,
    }),
  ),
  handleChange: PropTypes.func,
};
