import { convertListToObject } from './convertListToObject';
import { mapFiltersList } from './mapFiltersList';

export const getSimpleFiltersFromList = (filterList, mapSimpleFilters) => {
  if (filterList.length === 0) return [];

  const availableSimpleFiltersKeys = Object.keys(mapSimpleFilters);

  const fieldsWithSimpleKey = filterList.reduce((fields, currentField) => {
    const fieldKey = Object.keys(currentField)[0];
    const isASimpleField = availableSimpleFiltersKeys.includes(fieldKey);

    return isASimpleField ? [...fields, currentField] : fields;
  }, []);

  const fieldsWithSimpleKeyObject = convertListToObject(fieldsWithSimpleKey);
  const mappedsimpleFilters = mapFiltersList(
    fieldsWithSimpleKeyObject,
    mapSimpleFilters,
  );

  return mappedsimpleFilters;
};
