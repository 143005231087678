// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import {
  setList,
  setSort,
} from '../../../../../stores/Filters/UsedVehicles/FilterV2';
import { applyURLSearchParams } from '../../../../../utils/filters/usedVehicles/applyURLSearchParams';

class ButtonOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownIsOpen: false,
      sortItem: this.props.defaultSort,
    };

    this.addEventListeners = this.addEventListeners.bind(this);
    this.handleClickButtonOrder = this.handleClickButtonOrder.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleCloseOrderDropdown = this.handleCloseOrderDropdown.bind(this);
    this.recoverSortState = this.recoverSortState.bind(this);
  }

  componentDidMount() {
    this.addEventListeners();
    this.recoverSortState();
  }

  handleClickButtonOrder() {
    this.setState(oldState => ({
      ...oldState,
      dropdownIsOpen: !oldState.dropdownIsOpen,
    }));
  }

  handleCloseOrderDropdown() {
    this.setState(oldState => ({
      ...oldState,
      dropdownIsOpen: false,
    }));
  }

  async handleSortChange(sortItem) {
    this.setState(oldState => ({
      ...oldState,
      sortItem,
    }));

    setSort(sortItem);
    await setList();

    if (this.props.shouldSetURLSearchParams)
      applyURLSearchParams({ sort: sortItem });

    this.handleCloseOrderDropdown();
  }

  addEventListeners() {
    window.addEventListener('click', event => {
      if (!this.dropdownOrderRef) return;

      const isNotDropdownOrder = event.target !== this.dropdownOrderRef;
      const isNotInnerDropdownOrder = !this.dropdownOrderRef.contains(
        event.target,
      );

      if (
        isNotDropdownOrder &&
        isNotInnerDropdownOrder &&
        this.state.dropdownIsOpen
      ) {
        this.handleCloseOrderDropdown();
      }
    });
  }

  recoverSortState() {
    const { sort } = window.store.FilterV2.getState();

    this.setState(oldState => ({
      ...oldState,
      sortItem: sort,
    }));
  }

  render() {
    const { dropdownIsOpen } = this.state;
    const { sortOptions, buttonLabel, dropdownLabel, showDropdownCloseButton } =
      this.props;

    return (
      <div
        ref={node => {
          this.dropdownOrderRef = node;
        }}
        className="dropdown-order search-filter-simple__drop search-filter-simple__item"
      >
        <button
          className="button-order btn button button--large button--line-white"
          type="button"
          onClick={this.handleClickButtonOrder}
        >
          <span>
            <span className="d-none" data-testid="close">
              Fechar
            </span>
            <span className="" data-testid="open">
              <i className="icon icon-order" />
              {buttonLabel}
            </span>
          </span>
        </button>
        <div className="filter-order-dropdown" data-is-open={dropdownIsOpen}>
          <span className="filter-order-dropdown__header">
            {dropdownLabel}

            {showDropdownCloseButton && (
              <button type="button" onClick={this.handleCloseOrderDropdown}>
                <i className="icon icon-close" />
              </button>
            )}
          </span>
          <ul className="filter-order-dropdown__list">
            {sortOptions.map(sortItem => (
              <li
                key={sortItem.value}
                className="filter-order-dropdown__sort-option"
                data-is-active={sortItem.value === this.state.sortItem}
              >
                <button
                  className="filter-order-dropdown__sort-option-button"
                  type="button"
                  onClick={() => this.handleSortChange(sortItem.value)}
                >
                  <strong
                    className="filter-order-dropdown__sort-option-label
                  "
                  >
                    {sortItem.label}
                  </strong>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

ButtonOrder.defaultProps = {
  sortOptions: [
    { label: 'Mais relevante', value: '-featured,-ordination' },
    { label: 'Maior preço', value: '-price' },
    { label: 'Menor preço', value: '+price' },
    { label: 'Marca A-Z', value: '+brand.keyword' },
    { label: 'Marca Z-A', value: '-brand.keyword' },
    { label: 'Modelo A-Z', value: '+name.keyword' },
    { label: 'Modelo Z-A', value: '-name.keyword' },
  ],
  shouldSetURLSearchParams: false,
  defaultSort: null,
  buttonLabel: 'Ordenar',
  dropdownLabel: 'Ordenar por:',
  showDropdownCloseButton: false,
};

ButtonOrder.propTypes = {
  shouldSetURLSearchParams: PropTypes.bool,
  defaultSort: PropTypes.string,
  sortOptions: PropTypes.arrayOf(),
  buttonLabel: PropTypes.string,
  dropdownLabel: PropTypes.string,
  showDropdownCloseButton: PropTypes.bool,
};

export default ButtonOrder;
