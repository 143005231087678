export const mobileFieldsPlaceholder = {
  brand: 'Marca',
  model: 'Modelo',
  keyword: 'Palavra-chave. Ex.: Flex, 1.0...',
  min_price: 'Preço mínimo',
  max_price: 'Preço máximo',
  min_model_year: 'Ano mínimo',
  max_model_year: 'Ano máximo',
  min_km: 'Km mínima',
  max_km: 'Km máxima',
  unit: 'Unidade',
};

export const desktopFieldsPlaceholder = {
  brand: 'Escolha a marca',
  model: 'Escolha o modelo',
  keyword: 'Palavra-chave. Ex.: 1.4 8V, Flex, Auto...',
  min_price: 'Preço mínimo',
  max_price: 'Preço máximo',
  min_model_year: 'Ano mínimo',
  max_model_year: 'Ano máximo',
  min_km: 'Quilometragem mínima',
  max_km: 'Quilometragem máxima',
  unit: 'Escolha uma unidade',
};
