// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';

class AdvancedFilterButtonSubmit extends Component {
  render() {
    const { shouldSpan, handleSubmit, buttonLabel } = this.props;

    return (
      <button
        className="btn button button--large button--dark advanced-filter__submit"
        data-should-span={shouldSpan}
        onClick={handleSubmit}
        type="button"
        data-field-large
      >
        {buttonLabel} <i className="icon icon-search-b" />
      </button>
    );
  }
}

AdvancedFilterButtonSubmit.defaultProps = {
  shouldSpan: true,
  handleSubmit: () => {},
  buttonLabel: 'Buscar ofertas',
};

AdvancedFilterButtonSubmit.propTypes = {
  shouldSpan: PropTypes.bool,
  handleSubmit: PropTypes.func,
  buttonLabel: PropTypes.func,
};

export default AdvancedFilterButtonSubmit;
