// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../../Cards/UsedVehicles/ClassicUsedVehiclesCard';
import { autoServiceApiV2 } from '../../../services/autoServiceApiV2';
import PlaceholderCard from '../../Cards/Placeholder';

export default class UsedVehiclesFeaturedCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usedVehiclesList: [],
      isFetching: true,
    };

    this.carousel = null;

    this.initCarousel = this.initCarousel.bind(this);
    this.fetchUsedVehiclesFeatured = this.fetchUsedVehiclesFeatured.bind(this);
  }

  componentDidMount() {
    this.initCarousel();
    this.fetchUsedVehiclesFeatured();
  }

  componentDidUpdate() {
    this.initCarousel();
  }

  async fetchUsedVehiclesFeatured() {
    let usedVehiclesListResult = [];

    try {
      const requestParams = [{ featured: true }, { sort: '-pictures_count' }];
      const result = await autoServiceApiV2.getUsedModels(requestParams);
      const usedVehiclesFeaturedList = result
        ? result.entries
        : this.state.newVehicles;

      usedVehiclesListResult = usedVehiclesFeaturedList;
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(oldState => ({
        ...oldState,
        usedVehiclesList: usedVehiclesListResult,
        isFetching: false,
      }));
    }
  }

  initCarousel() {
    if (!this.elCarousel) return;

    const { isFetching, usedVehiclesList } = this.state;
    if (!isFetching && usedVehiclesList.length <= 0) return;

    if (this.carousel) this.carousel.destroy();

    const carousel = window.makeCarousel(this.elCarousel, {
      gap: 30,
      perPage: 4,
      perMove: 4,
      pagination: false,
      lazyLoad: 'nearby',
      preloadPages: 0,
      rewind: false,
      bound: true,
      focus: 'center',
      breakpoints: {
        560: {
          perPage: 1,
          perMove: 1,
        },
        992: {
          perPage: 2,
        },
        1270: {
          perPage: 3,
        },
        1400: {
          perPage: 4,
        },
      },
    });

    this.carousel = carousel;
    window.matchHeight.match('.used-vehicles-featured-component');
  }

  render() {
    const { usedVehiclesList, isFetching } = this.state;
    const { showUnitTag, moduleLink } = this.props;

    return (
      <div className="used-vehicles-featured-component">
        <h2 className="used-vehicles-featured-component__title">
          Nossas ofertas em destaque
        </h2>
        <div
          ref={node => {
            this.elCarousel = node;
          }}
          className="carousel used-vehicles-featured-carousel splide"
        >
          <div className="splide__track">
            {isFetching && (
              <ul className="used-models-featured__carousel-items-wrapper splide__list">
                {Array.from({ length: 5 }).map(item => (
                  <li
                    className="used-models-featured__carousel-placeholder-item carousel__item splide__slide"
                    key={item}
                  >
                    <PlaceholderCard long />
                  </li>
                ))}
              </ul>
            )}
            {!isFetching && usedVehiclesList.length > 0 && (
              <ul className="used-models-featured__carousel-items-wrapper splide__list">
                {usedVehiclesList.map((usedModel, index) => (
                  <li
                    className="used-models-featured__carousel-item carousel__item splide__slide"
                    key={usedModel.slug}
                  >
                    <Card
                      id={usedModel.id}
                      badge={usedModel.badge}
                      model={usedModel.model}
                      moduleLink={moduleLink}
                      itemImage={usedModel.item_image.middle_image}
                      itemUnit={usedModel.item_unit}
                      name={usedModel.name}
                      oldPrice={usedModel.old_price}
                      price={usedModel.price_value || usedModel.price}
                      seals={usedModel.seals}
                      showUnitTag={showUnitTag}
                      slug={usedModel.slug}
                      subtitle={usedModel.subtitle}
                      isOnFirstPage={index < 4}
                    />
                  </li>
                ))}
              </ul>
            )}
            {!isFetching && usedVehiclesList.length <= 0 && (
              <p className="used-models-featured__no-vehicles-message">
                Nenhum veículo seminovo em destaque encontrado.
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

UsedVehiclesFeaturedCarousel.defaultProps = {
  showUnitTag: false,
  moduleLink: '/seminovos',
};

UsedVehiclesFeaturedCarousel.propTypes = {
  showUnitTag: PropTypes.bool,
  moduleLink: PropTypes.string,
};
