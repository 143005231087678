import { createStore, createEffect } from 'effector';
import { getUsedModelsFilterOptions } from '../../../utils/filters/usedVehicles/getUsedModelsFilterOptions';

export const setOptions = createEffect('set options').use(
  getUsedModelsFilterOptions,
);

const FilterV2Options = (() => {
  let instance;

  return (
    defaultValue = {
      options: {},
      loading: false,
      hasError: false,
    },
  ) => {
    if (!instance) {
      instance = createStore(defaultValue);
    }

    instance.on(setOptions.pending, (state, loading) => ({
      ...state,
      loading,
    }));

    instance.on(setOptions.fail, state => ({
      ...state,
      loading: false,
      hasError: true,
    }));

    instance.on(setOptions.done, (state, res) => ({
      ...state,
      options: res.result.options,
      loading: false,
    }));

    return instance;
  };
})();

export default FilterV2Options;
