import { Component } from 'preact';
import PropTypes from 'prop-types';

class ButtonLoadMore extends Component {
  render() {
    const { buttonText } = this.props
    
    return (
      <div className="button-load-more__wrapper">
        <button
          className="button btn button--primary-line button--large used-vehicles-listing__button-load-more"
          type="button"
          onClick={this.props.handleLoadMore}
        >
          {this.props.loadingMore ? (
            <span>Carregando...</span>
          ) : (
            <span>
              {buttonText}{' '}
              <i className="icon icon-arrow-pointing-to-right" />
            </span>
          )}
        </button>
      </div>
    );
  }
}

ButtonLoadMore.defaultProps = {
  loadingMore: false,
  handleLoadMore: () => {},
  buttonText: 'Quero ver mais modelos',
};

ButtonLoadMore.propTypes = {
  loadingMore: PropTypes.bool,
  handleLoadMore: PropTypes.func,
  buttonText: PropTypes.string,
};

export default ButtonLoadMore;
