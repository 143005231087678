/* eslint-disable no-prototype-builtins */
import {
  setComponentsExhibition,
  setFiltersList,
  setKeyword,
  setList,
  setSort,
} from '../../../stores/Filters/UsedVehicles/FilterV2';

const getFiltersListFromURL = () => {
  const url = window.location.href;
  const urlObj = new URL(url);
  const searchParams = new URLSearchParams(urlObj.search);

  const paramsList = [];
  searchParams.forEach((value, key) => {
    paramsList.push({ [key]: value });
  });

  return paramsList;
};

export const restoreFilterFromURLParams = (storeMethods = {
  setFiltersList,
  setList,
  setComponentsExhibition
}) => {
  const filtersList = getFiltersListFromURL();
  if (filtersList.length === 0) return;

  storeMethods.setFiltersList(filtersList);

  const searchParam = filtersList.find(param => param.hasOwnProperty('search'));
  if (searchParam) setKeyword(searchParam.search);

  const sortParam = filtersList.find(param => param.hasOwnProperty('sort'));
  if (sortParam) setSort(sortParam.sort);

  storeMethods.setList();
  storeMethods.setComponentsExhibition({
    results: true,
  });
};
