export const mapFiltersV2 = {
  keyword: 'search',
  kind: 'kind',
  category: 'category[]',
  categories: 'category[]',
  brands: 'brand[]',
  brand: 'brand[]',
  model: 'model',
  models: 'models[]',
  transmissions: 'exchange[]',
  exchange: 'exchange[]',
  color: 'color[]',
  colors: 'color[]',
  min_price: 'min_price',
  max_price: 'max_price',
  min_km: 'min_km',
  max_km: 'max_km',
  min_model_year: 'min_model_year',
  max_model_year: 'max_model_year',
  unit: 'unit_id[]',
  units: 'unit_id[]',
  state: 'unit_state',
  city: 'unit_city',
  sort: 'sort',
};
