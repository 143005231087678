export const usedModelsKmOptions = [
  { label: '0 Km', value: 0 },
  { label: '5.000 Km', value: 5000 },
  { label: '10.000 Km', value: 10000 },
  { label: '20.000 Km', value: 20000 },
  { label: '30.000 Km', value: 30000 },
  { label: '40.000 Km', value: 40000 },
  { label: '60.000 Km', value: 60000 },
  { label: '80.000 Km', value: 80000 },
  { label: '100.000 Km', value: 100000 },
  { label: '120.000 Km', value: 120000 },
  { label: '140.000 Km', value: 140000 },
  { label: '160.000 Km', value: 160000 },
  { label: '180.000 Km', value: 180000 },
  { label: '200.000 Km', value: 200000 },
  { label: '250.000 Km', value: 250000 },
  { label: '300.000 Km', value: 300000 },
  { label: '400.000 Km', value: 400000 },
  { label: '500.000 Km', value: 500000 },
];
