import { mapFiltersV2 } from './mapFiltersV2';

const getURLSearchParams = (filtersList, mapFilters) => {
  const params = [];

  Object.entries(filtersList).forEach(([key, value]) => {
    if (value || typeof value === 'boolean') {
      const query = mapFilters[key];
      params.push([query, value]);
    }
  });

  return params;
};

export const applyURLSearchParams = (
  filtersList,
  mapFilters = mapFiltersV2,
) => {
  const params = getURLSearchParams(filtersList, mapFilters);
  const url = new URL(window.location);
  const searchParams = new URLSearchParams(params);

  url.search = decodeURI(searchParams.toString());

  window.history.pushState(null, null, url);
};
