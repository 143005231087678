// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';

class SearchResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: '',
      total: null,
    };

    this.watchStore();
  }

  watchStore() {
    window.store.FilterV2.watch(state => {
      this.setState(oldState => ({
        ...oldState,
        keyword: state.keyword,
        total: state.total,
      }));
    });
  }

  render() {
    const { keyword, total } = this.state;

    return (
      <div className="search-result">
        {total > 0 && keyword && (
          <div className="search-result__keyword-wrapper">
            Buscando por &quot;
            <span className="search-result__keyword">{keyword}</span>&quot;
          </div>
        )}
        <div className="search-result__total-wrapper">
          {total > 0 ? (
            <div>
              <span className="search-result__total">{total}</span>{' '}
              {total > 1 ? 'resultados encontrados' : 'resultado encontrado'}
            </div>
          ) : (
            <div>
              <span>
                <b>Nenhum resultado encontrado {keyword && 'para '}</b>
                {keyword && (
                  <strong className="text-uppercase">
                    &quot;{keyword}&quot;
                  </strong>
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SearchResult;
