import { unmapSimpleFiltersV2 } from './unmapFiltersV2';
import { getSimpleFiltersFromList } from './getSimpleFiltersFromList';
import { convertListToObject } from './convertListToObject';

export const mapFiltersListToComponentState = filtersList => {
  const simpleFilters = getSimpleFiltersFromList(
    filtersList,
    unmapSimpleFiltersV2,
  );

  const mappedFilters = [...simpleFilters];
  const mappedFiltersObject = convertListToObject(mappedFilters);

  return mappedFiltersObject;
};
