// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import ButtonAdvancedFilter from './components/ButtonAdvancedFilter';
import ButtonOrder from './components/ButtonOrder';
import SearchTerm from './components/SearchTerm';
import SearchResult from '../SearchResult';
import AdvancedSearchResult from '../AdvancedSearchResult';

class SimpleFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showResults: false,
    };

    this.watchStore();
  }

  watchStore() {
    window.store.FilterV2.watch(state => {
      this.setState(oldState => ({
        ...oldState,
        showResults: state.componentsExhibition.results,
      }));
    });
  }

  renderSearchResult() {
    const { showResults } = this.state;
    const { showAdvancedSearchResult } = this.props;

    if (showAdvancedSearchResult) return null;

    return showResults && <SearchResult />;
  }

  renderSearchTerm() {
    const { showResults } = this.state;
    const { shouldSetURLSearchParams, showAdvancedSearchResult } = this.props;

    if (showAdvancedSearchResult) {
      return <SearchTerm shouldSetURLSearchParams={shouldSetURLSearchParams} />;
    }

    return (
      !showResults && (
        <SearchTerm shouldSetURLSearchParams={shouldSetURLSearchParams} />
      )
    );
  }

  render() {
    const { showResults } = this.state;
    const {
      shouldSetURLSearchParams,
      buttonFilterLabel,
      buttonOrderLabel,
      dropdownOrderLabel,
      showDropdownCloseButton,
      showAdvancedSearchResult,
    } = this.props;

    return (
      <form
        className="filter-simple"
        onSubmit={event => event.preventDefault()}
        data-show-results={showResults}
      >
        {this.renderSearchResult()}

        <div className="filter-simple__buttons">
          <ButtonAdvancedFilter buttonLabel={buttonFilterLabel} />
          <ButtonOrder
            shouldSetURLSearchParams={shouldSetURLSearchParams}
            buttonLabel={buttonOrderLabel}
            dropdownLabel={dropdownOrderLabel}
            showDropdownCloseButton={showDropdownCloseButton}
          />
        </div>

        {this.renderSearchTerm()}

        {showResults && showAdvancedSearchResult && <AdvancedSearchResult />}
      </form>
    );
  }
}

SimpleFilter.defaultProps = {
  shouldSetURLSearchParams: false,
  buttonFilterLabel: 'Filtrar',
  buttonOrderLabel: 'Ordenar',
  dropdownOrderLabel: 'Ordenar por:',
  showDropdownCloseButton: false,
  showAdvancedSearchResult: true,
};

SimpleFilter.propTypes = {
  shouldSetURLSearchParams: PropTypes.bool,
  buttonFilterLabel: PropTypes.string,
  buttonOrderLabel: PropTypes.string,
  dropdownOrderLabel: PropTypes.string,
  showDropdownCloseButton: PropTypes.bool,
  showAdvancedSearchResult: PropTypes.bool,
};

export default SimpleFilter;
